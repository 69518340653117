import React from 'react';
import {CheckoutButton} from '../../../../../common/components/CheckoutButton/CheckoutButton';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import s from './CheckoutButtons.scss';
import {useEnvironment} from '@wix/yoshi-flow-editor';
import {ExpressCheckoutButtons} from '@wix/ecom-platform-express-checkout-buttons';
import {useSettings} from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';

export enum CheckoutButtonsDataHook {
  Default = 'CheckoutButtons.default',
  Top = 'CheckoutButtons.topView',
  Wrapper = 'CheckoutButtons.Wrapper',
}

interface CheckoutButtonsProps {
  isOnTop?: boolean;
}

export function CheckoutButtons(props: CheckoutButtonsProps) {
  const {shouldShowDynamicPaymentOptions, dynamicPaymentOptionsProps} = useControllerProps().cashierExpressStore;
  const {cart, shouldShowExpressCheckout, isFreeCart, isCardTokenizationCart, shouldSupportCardTokenizationOnCart} =
    useControllerProps().cartStore;
  const {isCheckoutRegularFlowEnabled} = useControllerProps().paymentMethodStore;
  const {isCheckoutButtonDisabled, isNavigationToCheckoutInProcess} = useControllerProps().navigationStore;
  const {get: getSettings} = useSettings();
  const {isLoading} = useControllerProps();
  const {isSSR} = useEnvironment();
  const {isOnTop} = props;
  const {total} = cart.totals;
  const dataHook = isOnTop ? CheckoutButtonsDataHook.Top : CheckoutButtonsDataHook.Default;
  const shouldRequestShipping = dynamicPaymentOptionsProps?.shouldRequestShipping ?? false;
  const canCheckout = dynamicPaymentOptionsProps?.canCheckout ?? false;

  const showCheckoutButton = isCheckoutRegularFlowEnabled || isFreeCart;

  const shouldHandleCardTokenizationOnCart = shouldSupportCardTokenizationOnCart && isCardTokenizationCart;

  return (
    <div data-hook={dataHook} className={s.checkoutButtons}>
      {showCheckoutButton && (
        <CheckoutButton
          disabled={isCheckoutButtonDisabled || isLoading}
          text={getSettings(settingsParams.CART_BUTTON_TEXT) as string}
          isNavigationToCheckoutInProcess={isNavigationToCheckoutInProcess}
        />
      )}
      {shouldShowDynamicPaymentOptions && !isSSR && shouldShowExpressCheckout && !shouldHandleCardTokenizationOnCart ? (
        <div className={s.expressButtonsContainer}>
          <ExpressCheckoutButtons
            layout={'vertical'}
            isShippable={shouldRequestShipping}
            estimatedTotal={total.toString()}
            canStartPayment={canCheckout}
            theme={dynamicPaymentOptionsProps?.getButtonTheme}
            buttonStyle={dynamicPaymentOptionsProps?.buttonStyle}
            dataHook={CheckoutButtonsDataHook.Wrapper}
          />
        </div>
      ) : null}
    </div>
  );
}
