import React from 'react';
import {CartItemDataHook} from '../../CartItem';
import {useControllerProps} from '../../../../../../domain/controllers/ControllerContext';
import {classes} from '../../CartItem.st.css';
import {ICartItem} from '../../../../../../types/app.types';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {DeleteSmall} from '@wix/wix-ui-icons-common/on-stage';

export interface RemoveProps {
  item: ICartItem;
  onRemoveItem?: () => void;
}

export const Remove = ({item, onRemoveItem}: RemoveProps) => {
  const {t} = useTranslation();
  const {removeItemFromCart} = useControllerProps().cartStore;
  const handleRemoveItem = () => {
    removeItemFromCart(item);
    onRemoveItem?.();
  };

  return (
    <button
      className={classes.remove}
      type="button"
      aria-label={t('cart.sr_remove_item_from_cart', {item_name: item.product.name})}
      onClick={handleRemoveItem}
      data-hook={CartItemDataHook.Remove}>
      <DeleteSmall />
    </button>
  );
};
