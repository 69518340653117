import React from 'react';
import {getHref} from '../../../../../../domain/utils/itemUtils';
import {ICartItem} from '../../../../../../types/app.types';
import {useControllerProps, UseControllerPropsResult} from '../../../../../../domain/controllers/ControllerContext';
import {STORES_APP_DEF_ID} from '@wix/wixstores-client-core';

export enum ItemLinkDataHooks {
  Anchor = 'ItemLinkDataHooks.Anchor',
  NonClickableItem = 'ItemLinkDataHooks.NonClickableItem',
}

const handleClick = (
  event: React.MouseEvent<HTMLAnchorElement>,
  navigationStore: UseControllerPropsResult['navigationStore'],
  urlPart: string
) => {
  event.preventDefault();
  event.stopPropagation();
  return navigationStore.navigateToProduct(urlPart);
};

export const ItemLink = ({
  item,
  children,
  tabIndex,
}: {
  item: ICartItem;
  children: React.ReactChild;
  tabIndex?: number;
}) => {
  const {cartStore, navigationStore} = useControllerProps();
  const href = getHref(cartStore, item);
  const shouldRenderNonClickableItem = item.product.urlPart === '' || item.catalogAppId !== STORES_APP_DEF_ID;

  return shouldRenderNonClickableItem ? (
    <div data-hook={ItemLinkDataHooks.NonClickableItem}>{children}</div>
  ) : (
    <a
      href={href}
      onClick={(event) => handleClick(event, navigationStore, item.product.urlPart)}
      {...(tabIndex !== undefined ? {tabIndex} : {})}
      data-hook={ItemLinkDataHooks.Anchor}>
      {children}
    </a>
  );
};
