import React from 'react';
import s from './CartItem.scss';
import {classes} from './CartItem.st.css';
import {CartItemThumbnail} from '../CartItemThumbnail/CartItemThumbnail';
import {Name} from './partials/Name/Name';
import {Discounts} from './partials/Discounts/Discounts';
import {Options} from './partials/Options/Options';
import {Price} from './partials/Price/Price';
import {Remove} from './partials/Remove/Remove';
import {ICartItem} from '../../../../types/app.types';
import {Quantity} from './partials/Quantity/Quantity';
import {TotalPrice} from './partials/TotalPrice/TotalPrice';
import {OutOfStock} from './partials/OutOfStock/OutOfStock';
import {PaymentTypeLabel} from './partials/PaymentTypeLabel/PaymentTypeLabel';
import {hasFreeText, shouldPresentMoreDetails} from '../../../../domain/utils/itemUtils';
import {useControllerProps} from '../../../../domain/controllers/ControllerContext';
import {PaymentOptionType, Severity, Violation} from '@wix/wixstores-graphql-schema-node';
import {ViolationNotification} from '../../Violations/ViolationNotification/ViolationNotification';
import {guidToInt} from '../../../../domain/utils/guiToInt';
import {Spinner, SpinnerTypes} from 'wix-ui-tpa';
import {Divider} from 'wix-ui-tpa/cssVars';
import classNames from 'classnames';
import {CartItemOptions} from './partials/CartItemOptions/CartItemOptions';
import {useTranslation} from '@wix/yoshi-flow-editor';

export enum CartItemDataHook {
  'Root' = 'CartItemDataHook.root',
  'ComparePrice' = 'CartItemDataHook.comparePrice',
  'Name' = 'CartItemDataHook.name',
  'Options' = 'CartItemDataHook.options',
  'FreeText' = 'CartItemDataHook.freeText',
  'Price' = 'CartItemDataHook.price',
  'PaymentTypeLabel' = 'CartItemDataHook.paymentTypeLabel',
  'Quantity' = 'CartItemDataHook.quantity',
  'QuantityErrorTooltip' = 'CartItemDataHook.quantityErrorTooltip',
  'OutOfStockRoot' = 'CartItemDataHook.outOfStockRoot',
  'OutOfStockError' = 'CartItemDataHook.outOfStockError',
  'Remove' = 'CartItemDataHook.remove',
  'TotalPrice' = 'CartItemDataHook.totalPrice',
  'Discounts' = 'CartItemDataHook.Discounts',
  'DiscountName' = 'CartItemDataHook.DiscountName',
  'Divider' = 'CartItemDataHook.divider',
  'loadingSpinner' = 'CartItemDataHook.loadingSpinner',
}

interface CartItemProps {
  item: ICartItem;
  showQuantityBySettings: boolean;
  showThumbnailBySettings: boolean;
  shouldShowItemInfoBySettings: boolean;
  showDivider: boolean;
  onRemoveItem?: () => void;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const CartItem: React.FC<CartItemProps> = (props: CartItemProps) => {
  const {
    item,
    showQuantityBySettings,
    showThumbnailBySettings,
    shouldShowItemInfoBySettings,
    showDivider,
    onRemoveItem,
  } = props;
  const {
    cartStore: {
      shouldDisplayViolations,
      loadingItems,
      shouldUseChevronForItemOptions,
      cart: {violations},
      shouldFixCartItemOptionsBug,
    },
    isLoading,
  } = useControllerProps();

  const {t} = useTranslation();

  const shouldHideQuantity = item.renderingConfig?.hideQuantity || !showQuantityBySettings;
  const shouldHidePrice = item.renderingConfig?.hidePrice;
  const shouldShowPaymentTypeLabel = item.paymentType && item.paymentType !== PaymentOptionType.FULL_PAYMENT_ONLINE;
  const isItemOutOfStock = item.inventoryQuantity === 0;

  const getLineItemViolationIfItExists = (): Violation | undefined => {
    if (shouldDisplayViolations) {
      return (
        violations.find(
          (violation) =>
            guidToInt(violation.target?.lineItem?.id) === item.cartItemId && violation.severity === Severity.ERROR
        ) ??
        violations.find(
          (violation) =>
            guidToInt(violation.target?.lineItem?.id) === item.cartItemId && violation.severity === Severity.WARNING
        )
      );
    }
  };

  const lineItemViolation = getLineItemViolationIfItExists();
  const shouldShowLineItemViolation = !isItemOutOfStock && Boolean(lineItemViolation);
  const isItemLoading = isLoading && loadingItems.includes(item.cartItemId);

  const contentClassName = () => {
    if (shouldUseChevronForItemOptions) {
      return s.contentChevron;
    } else {
      return s.content;
    }
  };

  return (
    <>
      <div className={s.itemWrapper} data-hook={CartItemDataHook.Root}>
        <div className={classNames(s.item, ...(isItemLoading ? /* istanbul ignore next */ [s.loading] : []))}>
          <div className={s.info}>
            {isItemLoading ? (
              /* istanbul ignore next */ <Spinner
                data-hook={CartItemDataHook.loadingSpinner}
                isCentered
                type={SpinnerTypes.slim}
              />
            ) : undefined}
            {showThumbnailBySettings && <CartItemThumbnail item={item} />}
            <div className={s.details}>
              <div className={contentClassName()}>
                <Name item={item} />
                <Discounts item={item} shouldUseChevronForItemOptions={shouldUseChevronForItemOptions}></Discounts>
                {!shouldHidePrice && (
                  <Price item={item} shouldUseChevronForItemOptions={shouldUseChevronForItemOptions} />
                )}
                {shouldShowPaymentTypeLabel && <PaymentTypeLabel item={item} />}
                {shouldPresentMoreDetails(item, shouldFixCartItemOptionsBug) &&
                  shouldShowItemInfoBySettings &&
                  (shouldUseChevronForItemOptions ? (
                    <CartItemOptions
                      itemId={item.cartItemId}
                      options={[...item.optionsSelectionsValues, ...item.freeText]}
                      maxOptionValLength={500}
                      collapseSettings={{
                        numberOfOptionsToCollapseAfter: 1,
                        showMoreTitle: t('sideCart.lineItems.moreDetails'),
                        showLessTitle: t('sideCart.lineItems.lessDetails'),
                      }}
                    />
                  ) : (
                    <Options
                      itemId={item.cartItemId}
                      options={item.optionsSelectionsValues}
                      dataHook={CartItemDataHook.Options}
                    />
                  ))}
                {hasFreeText(item) && !shouldUseChevronForItemOptions && (
                  <Options itemId={item.cartItemId} options={item.freeText} dataHook={CartItemDataHook.FreeText} />
                )}
              </div>
              {
                <div className={s.priceAndQuantity}>
                  {!shouldHideQuantity && <Quantity item={item} />}
                  <TotalPrice item={item} />
                </div>
              }
            </div>
          </div>
          <div className={s.colEnd}>
            <Remove item={item} onRemoveItem={onRemoveItem} />
          </div>
        </div>
        {isItemOutOfStock && <OutOfStock />}
        {shouldShowLineItemViolation && (
          <div className={s.violation}>
            <ViolationNotification violation={lineItemViolation} />
          </div>
        )}
      </div>
      {showDivider ? <Divider className={classes.divider} data-hook={CartItemDataHook.Divider} /> : null}
    </>
  );
};
